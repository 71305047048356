import { Button, buttonColorSchemes } from "@/components/Shared/Button/Button";
import { Agreement } from "@/components/Shared/FormControl/Agreement";
import { FormControlGroup } from "@/components/Shared/FormControl/FormControlGroup";
import { FormControlGroupTextarea } from "@/components/Shared/FormControl/FormControlGroupTextarea";
import { awakeHerokuBackend } from "@/lib/api/gqlApi";
import { emailRegex } from "@/lib/consts";
import { createOrder } from "@/lib/features/order/api/createOrder";
import { Order, OrderStatus } from "@/lib/features/order/model/order.model";
import { Flex, GridItem, SimpleGrid, Stack, useToast } from "@chakra-ui/react";
import { navigate } from "gatsby-link";
import React, { ReactNode, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

export interface OrderFormProps {
  firstNamePlaceholder: string;
  lastNamePlaceholder: string;
  phonePlaceholder: string;
  emailPlaceholder: string;
  textaAreaPlaceholder: string;
  firstNameLabel?: string;
  lastNameLabel?: string;
  phoneLabel?: string;
  emailLabel?: string;
  textaAreaLabel?: string;
  bindingAgreementText: string | ReactNode | ReactNode[];
  gdprAgreementText: string | ReactNode | ReactNode[];
  cta: { text: string; type?: buttonColorSchemes };
  courseId: number;
}

const DEAFULT_VALIDATION_MESSAGE = "Toto pole je povinné";

export const OrderForm = (props: OrderFormProps) => {
  const {
    firstNamePlaceholder,
    lastNamePlaceholder,
    phonePlaceholder,
    emailPlaceholder,
    textaAreaPlaceholder,
    firstNameLabel,
    lastNameLabel,
    phoneLabel,
    emailLabel,
    textaAreaLabel,
    bindingAgreementText,
    gdprAgreementText,
    cta,
    courseId
  } = props;
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [isHerokuAwake, setIsHerokuAwake] = useState(false);

  const methods = useForm({});

  // awake heroku (if index page was not visited)
  useEffect(() => {
    try {
      awakeHerokuBackend();
      setIsHerokuAwake(true);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const onSubmit = async (formData: any) => {
    const { bindingAgreement, ...orderInput } = formData;
    orderInput.status = OrderStatus.pending;
    orderInput.course = courseId;

    setLoading(true);
    try {
      const createdOrder = await createOrder(orderInput as Order);
      navigate(`/objednavka-vytvorena?courseId=${courseId}`);
      setLoading(false);
      methods.reset();
    } catch (e) {
      console.log(e);
      toast({
        position: "top",
        title:
          "Ospravedlňujeme sa ale niekde nastala chyba. Prosím kontaktujte nás telefonicky.",
        status: "error",
        duration: 20000,
        isClosable: true
      });
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        noValidate
        action=""
        onSubmit={methods.handleSubmit(onSubmit)}
        className="w-full"
      >
        <Stack spacing={{ base: 3, lg: 5 }}>
          <SimpleGrid columns={{ md: 2 }} gap={{ base: 4, md: 5 }}>
            <FormControlGroup
              isRequired
              name="firstName"
              label={firstNameLabel || firstNamePlaceholder}
              placeholder={firstNamePlaceholder}
              validation={{
                required: {
                  value: true,
                  message: DEAFULT_VALIDATION_MESSAGE
                }
              }}
            />
            <FormControlGroup
              isRequired
              name="lastName"
              label={lastNameLabel || lastNamePlaceholder}
              placeholder={lastNamePlaceholder}
              validation={{
                required: {
                  value: true,
                  message: DEAFULT_VALIDATION_MESSAGE
                }
              }}
            />
            <FormControlGroup
              isRequired
              name="phone"
              label={phoneLabel || phonePlaceholder}
              placeholder={phonePlaceholder}
              validation={{
                required: {
                  value: true,
                  message: DEAFULT_VALIDATION_MESSAGE
                }
              }}
            />
            <FormControlGroup
              isRequired
              name="email"
              label={emailLabel || emailPlaceholder}
              placeholder={emailPlaceholder}
              validation={{
                pattern: {
                  value: emailRegex,
                  message: "Zadajte prosím Váš skutočný email!"
                },
                required: {
                  value: true,
                  message: "Zadajte prosím Váš email."
                }
              }}
            />
            <GridItem colSpan={{ md: 2 }}>
              <FormControlGroupTextarea
                name="note"
                rows={4}
                label={textaAreaLabel || textaAreaPlaceholder}
                placeholder={textaAreaPlaceholder}
              />
            </GridItem>
          </SimpleGrid>
          <Agreement name="bindingAgreement">{bindingAgreementText}</Agreement>
          <Agreement name="gdprAgreement">{gdprAgreementText}</Agreement>
          <Flex justifyContent="center" pt={6}>
            <Button
              type="submit"
              colorScheme={cta.type || "primary"}
              size="lg"
              isDisabled={!isHerokuAwake}
              isLoading={loading}
              loadingText="Vytvárame objednávku"
            >
              {cta.text}
            </Button>
          </Flex>
        </Stack>
      </form>
    </FormProvider>
  );
};
