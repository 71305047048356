import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage
} from "@chakra-ui/react";
import dot from "dot-object";
import React, { ReactNode } from "react";
import { Controller, useFormContext } from "react-hook-form";

export interface AgreementProps {
  children?: ReactNode;
  label?: string;
  name: string;
}

export const Agreement = (props: AgreementProps) => {
  const { children, label, name } = props;
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <div className="aggreement font-light">
      <Controller
        control={control}
        name={name}
        defaultValue={false}
        rules={{
          required: {
            value: true,
            message: "Musíte súhlasiť s podmienkami"
          }
        }}
        render={({
          field: { onChange, value, ref },
          formState: { errors }
        }) => {
          return (
            <FormControl isInvalid={dot.pick(name, errors)} fontSize="sm">
              <Flex color="body" fontWeight={400}>
                <Checkbox
                  onChange={onChange}
                  ref={ref}
                  isChecked={value}
                  colorScheme="blackAlpha"
                  pr={2}
                  size="lg"
                />
                {children || label}
              </Flex>
              <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </div>
  );
};
