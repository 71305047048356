import { CREATE_ORDER_MUTATION } from "../graphql/create-order-mutation";
import { Order } from "../model/order.model";
import { graphQLClient } from "./../../../api/gqlApi";

export const createOrder = async (order: Order): Promise<Order> => {
  const data = await graphQLClient.request(CREATE_ORDER_MUTATION, {
    order: order
  });

  return data.order;
};
