import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Textarea
} from "@chakra-ui/react";
import dot from "dot-object";
import React from "react";
import { useFormContext } from "react-hook-form";

export interface FormControlGroupTextareaProps extends FormControlProps {
  className?: string;
  label?: string;
  placeholder: string;
  validation?: Object;
  name: string;
  rows?: number;
}

export const FormControlGroupTextarea = (
  props: FormControlGroupTextareaProps
) => {
  const { className = "", label, placeholder, validation, name, rows } = props;

  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <FormControl {...props} isInvalid={dot.pick(name, errors)}>
      {label && (
        <FormLabel htmlFor={name} color="primary">
          {label}
        </FormLabel>
      )}
      <Textarea
        rounded="lg"
        borderColor="#C4C4C4"
        placeholder={placeholder}
        rows={rows}
        borderWidth={1}
        _placeholder={{
          color: "grey",
          fontWeight: 500
        }}
        py={3}
        focusBorderColor="primary"
        {...register(name, validation)}
      />

      <FormErrorMessage>{dot.pick(name, errors)?.message}</FormErrorMessage>
    </FormControl>
  );
};
