import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Input
} from "@chakra-ui/react";
import dot from "dot-object";
import React from "react";
import { useFormContext } from "react-hook-form";

export interface FormControlGroupProps extends FormControlProps {
  className?: string;
  label?: string;
  placeholder: string;
  type?: string;
  validation?: Object;
  name: string;
  ffref?: any;
}

export const FormControlGroup = (props: FormControlGroupProps) => {
  const {
    className = "",
    label,
    placeholder,
    validation,
    name,
    type = "text"
  } = props;

  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <FormControl {...props} isInvalid={dot.pick(name, errors)}>
      {label && (
        <FormLabel htmlFor={name} fontWeight="500" color="primary">
          {label}
        </FormLabel>
      )}
      <Input
        rounded="lg"
        _placeholder={{
          color: "grey",
          fontWeight: 500
        }}
        py={6}
        focusBorderColor="primary"
        borderColor="#C4C4C4"
        borderWidth={1}
        placeholder={placeholder}
        type={type}
        {...register(name, validation)}
      />
      <FormErrorMessage>{dot.pick(name, errors)?.message}</FormErrorMessage>
    </FormControl>
  );
};
