import { gql } from "graphql-request";

export const CREATE_ORDER_MUTATION = gql`
  mutation createOrder($order: OrderInput) {
    createOrder(input: { data: $order }) {
      order {
        gdprAgreement
        total
        status
        note
        firstName
        lastName
        email
        phone
        course {
          id
          slug
          name
        }
      }
    }
  }
`;
