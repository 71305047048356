import { SectionHeader } from "@/components/Shared/SectionHeader/SectionHeader";
import { Box, BoxProps, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { OrderForm } from "../OrderForm/OrderForm";

export interface CreateOrderSectionProps extends BoxProps {
  courseId: number;
}

export const CreateOrderSection = (props: CreateOrderSectionProps) => {
  const { courseId, ...restOfProps } = props;

  return (
    <Box {...restOfProps}>
      <Stack spacing={6} alignItems="center">
        <SectionHeader
          title="Objednávka"
          subtitle={
            courseId === 1 ? (
              <>
                Kurz predpôrodnej prípravy <br /> (od 23-36 týždňa tehotenstva)
              </>
            ) : (
              "Laktačné poradenstvo"
            )
          }
          textAlign="center"
        />
        <Stack spacing={3} textAlign="center" maxW="3xl">
          <Text fontSize="xl" fontWeight="700">
            Dodržiavame všetky aktuálne hygienické opatrenia COVID-19
          </Text>
          <Text as="p">
            Kurzy prebiehajú v rodinnej atmosfére, kde sa nám môžete zdôveriť
            svojimi otázkami / ťažkosťami.
          </Text>
          <Text as="p">
            Záleží nám na tom, aby ste sa u nás cítili príjemne. Pre to sú naše
            priestory zrekonštruované, príjemné a kľudné, aby ste sa u nás
            cítili uvoľnene.
          </Text>
          <Text as="p">
            Vážime si dôveru každej našej klientky, pre to sa snažíme každej
            klientke vísť v ústrety.
          </Text>
        </Stack>
        <Box maxW="4xl">
          <OrderForm
            firstNamePlaceholder="Meno"
            lastNamePlaceholder="Priezvisko"
            emailPlaceholder="Email"
            phonePlaceholder="Tel. kontakt"
            textaAreaLabel="Špeciálna požiadavka"
            textaAreaPlaceholder="Máte špeciálnu požiadavku?"
            bindingAgreementText="Záväzne objednávam Kurz predpôrodnej prípravy"
            gdprAgreementText="Udeľujem súhlas na spracovanie osobných údajov pre informačné účely. Pre viac informácií si môžete prečítať Pravidlá ochrany GDPR."
            cta={{ text: "Objednať" }}
            courseId={courseId}
          />
        </Box>
      </Stack>
    </Box>
  );
};
