export enum OrderStatus {
  pending = "pending",
  awaitingPayment = "awaitingPayment",
  completed = "completed",
  canceled = "canceled",
  refunded = "refunded"
}

export interface Course {
  id?: string;
  slug: string;
  name: string;
}

export interface Customer {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface Order {
  gdprAgreement: boolean;
  total: number;
  status: OrderStatus;
  note?: string;
  customer: Customer;
  courses: Course[];
}
