import { CreateOrderSection } from "@/components/Order/CreateOrderSection/CreateOrderSection";
import { Container } from "@/components/Shared/Container/Container";
import { Stack } from "@chakra-ui/layout";
import React from "react";
import Layout from "../components/Shared/Layout/Layout";
import { SEO } from "../components/Shared/SEO/SEO";

const OrderPage = (props: any) => {
  const courseId = Number(
    props.location.search[props.location.search.length - 1]
  );
  return (
    <Layout>
      <SEO description="This is an index page" />
      <Container>
        <Stack spacing={20} py={20}>
          <CreateOrderSection courseId={courseId} />
        </Stack>
      </Container>
    </Layout>
  );
};

export default OrderPage;
